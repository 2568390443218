import React from "react"
import styled from "styled-components"
import Service from "src/components/section/service/index"
import Container from "src/components/container"
import FormContact from "src/components/form-contact/index"
import SEO from "src/components/seo"

const StyledService = styled(Service)`
  h2:not(:first-child) {
    margin-top: 1.2em;
  }
  h2 {
    @media screen and (max-width: 540px) {
      font-size: 1.5rem;
    }
  }
`

export default function Pentest() {
  return (
    <Container>
      <SEO
        title="Тестирование на проникновение"
        description="Аудит и мониторинг безопасности сайта, инфраструктуры, мобильных приложений на основе тысяч паттернов реальных хакерских атак."
      />
      <section className="service h100">
        <div className="container">
          <StyledService>
            <h2>Тестирование на проникновение</h2>
            <div>
              Аудит и мониторинг безопасности сайта, инфраструктуры, мобильных
              приложений на основе тысяч паттернов реальных хакерских атак. Наша
              команда симулирует действия реальных злоумышленников, находит
              критические баги и уязвимости, как снаружи, так и внутри
              безопасного периметра, а затем предоставляет отчет с конкретными
              шагами по улучшению вашей защиты. А также будет проводить
              круглосуточный мониторинг безопасности сайта или его отдельных
              узлов при необходимости.
            </div>
            <h2>Как провести аудит безопасности сайта?</h2>
            <div>
              <strong>
                Оставьте заявку на проверку безопасности на нашем сайте.
              </strong>
              <ul>
                <li>
                  Мы определим цели и формате тестирования и определим уровень
                  безопасности сайта
                </li>
                <li>
                  Вы получите отчет о тестировании сайта на безопасность и наши
                  рекомендации
                </li>
              </ul>
            </div>
            <h2>Все возможные форматы тестов</h2>
            <div>
              <strong>Веб-пентест</strong>
              <ul>
                <li>
                  Проведем проверку безопасности сайта, чтобы убедиться в защите
                  веб-приложения от взлома, дадим расширенные рекомендации по
                  исправлению уязвимостей и проверим их реализацию.
                </li>
                <li>
                  Используем собственную методологию, включающую все проверки по
                  стандарту OWASP.
                </li>
              </ul>
              <strong>Red Teaming</strong>
              <ul>
                <li>
                  Проверка безопасности сайта или приложения в режиме Red Team
                  предполагает активное противодействие вашего отдела
                  безопасности, что позволит узнать насколько быстро и четко вам
                  удается реагировать на действия злоумышленников.
                </li>
                <li>
                  Проверим стабильность мониторинга и уровень осведомленности
                  сотрудников.
                </li>
              </ul>
            </div>
            <FormContact ticketType="pentest" />
          </StyledService>
        </div>
      </section>
    </Container>
  )
}
